.rov-geojson-leaflet table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.rov-geojson-leaflet td,
.rov-geojson-leaflet th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.rov-geojson-leaflet tr:nth-child(even) {
    background-color: #dddddd;
}