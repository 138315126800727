.react-flow__node-run .react-flow__handle {
    background: #e6e6e9;
}

.create-process-flow .react-flow__handle-connecting {
    background: #ff6060;
}

.create-process-flow .react-flow__handle-valid {
    background: #55dd99;
}