.leaflet-interactive:focus {
    outline: none;
}

.leaflet-image-layer {
    image-rendering: pixelated;
}

.leaflet-control-scale {
    position: absolute;
    bottom: 0px;
    right: 0px;
    background: rgba(255, 255, 255, 0.8);
    padding: 5px;
    border-radius: 3px;
    font-size: 14px;
    z-index: 500;
    pointer-events: none;
    text-align: center;
    margin: 0px !important;
}